import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useFormik } from "formik";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { callApi } from "../utils/fetchData";
import { useConfig } from "../utils/useConfig";
import { useData } from "../utils/useData";
import { useDynamicEmployeeValidationSchema } from "../utils/useDynamicValidationSchema";
import { AddressFinderForm } from "./AddressFinderForm/AddressFinderForm";

type PersonalDetailsFormProps = {
  showCancel: boolean;
  submitButtonText: string;
  onSuccess?: () => void;
  termsAndConditions?: boolean;
};

export type PersonalDetailsFormValuesType = {
  firstName: string;
  lastName: string;
  cardDisplayName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  // address3: string;
  // address4: string;
  // address5: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  country: string;
};

export const PersonalDetailsForm = ({
  showCancel,
  submitButtonText,
  onSuccess,
  termsAndConditions
}: PersonalDetailsFormProps) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const theme = useTheme();

  const { employee, userInfoData, employer } = useData();
  const navigator = useNavigate();
  const [isAcceptedTermsAndConditions, setIsAcceptedTermsAndConditions] =
    useState(false);
  const [error, setError] = useState("");

  const mutation = useMutation(
    async (formValues: PersonalDetailsFormValuesType) => {
      if (termsAndConditions && !isAcceptedTermsAndConditions)
        throw new Error("Please accept the terms and conditions to continue");

      if (!formValues.address1 || !formValues.postalCode)
        throw new Error(
          "Invalid Address Format. Please make sure all required fields are filled."
        );

      const countryCode: any = employer?.data?.country?.id;
      const formattedPhoneNumber = parsePhoneNumberFromString(
        formValues.phone,
        countryCode
      )?.number;

      const payload = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        cardDisplayName: formValues.cardDisplayName,
        contact: {
          email: formValues.email,
          phone: formattedPhoneNumber,
          address: {
            address1: formik.values.address1,
            address2: formik.values.address2,
            city: formik.values.city,
            // Only include state/province for outside of NZ
            stateOrProvince:
              employer.data?.country.id !== "NZ"
                ? formik.values.stateOrProvince
                : undefined,
            postalCode: formik.values.postalCode,
            country: employer.data?.country.id ?? ""
          }
        }
      };
      const response = await callApi(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/employees/${userInfoData?.employeeId}`,
        "PUT",
        payload,
        getToken
      );

      if (response && !response.ok)
        throw new Error("There was a problem updating your details");
    },
    {
      onSuccess: () => {
        toast.success("Details updated successfully");
        employee.refetch();
        onSuccess && onSuccess();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const validationSchema = useDynamicEmployeeValidationSchema(
    employer.data?.country.id ?? ""
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      firstName: employee.data?.firstName ?? "",
      lastName: employee.data?.lastName ?? "",
      cardDisplayName: employee.data?.cardDisplayName ?? "",
      email: employee.data?.contact.email ?? "",
      phone: employee.data?.contact.phone ?? "",
      address1: employee.data?.contact.address.address1 ?? "",
      address2: employee.data?.contact.address.address2 ?? "",
      // address3: employee.data?.contact.address.address3 ?? "",
      // address4: employee.data?.contact.address.address4 ?? "",
      // address5: employee.data?.contact.address.address5 ?? "",
      city: employee.data?.contact.address.city ?? "",
      stateOrProvince: employee.data?.contact.address.stateOrProvince ?? "",
      postalCode: employee.data?.contact.address.postalCode ?? "",
      country: employer.data?.country.id ?? ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutation.mutate(values);
    },
    enableReinitialize: true
  });

  const resetData = useCallback(() => {
    resetForm();
    setError("");
  }, [resetForm, setError]);

  useEffect(() => {
    if (employee.data) {
      resetData();
    }
  }, [employee.data, resetData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={formik.values.firstName}
            onChange={formik.handleChange}
            name="firstName"
            label="First name"
            required
            fullWidth
            size="small"
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formik.values.lastName}
            onChange={formik.handleChange}
            name="lastName"
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            label="Last name"
            required
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            label="Email"
            type="email"
            required
            disabled={true}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formik.values.phone}
            onChange={formik.handleChange}
            name="phone"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            label="Mobile Phone"
            required
            placeholder={
              employer.data?.country.id === "AU"
                ? "+61400000000"
                : "+64200000000"
            }
            fullWidth
            type="tel"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formik.values.cardDisplayName}
            onChange={formik.handleChange}
            label="Name to display on card (Max. 28 Characters)"
            name="cardDisplayName"
            required
            fullWidth
            size="small"
            error={
              formik.touched.cardDisplayName &&
              Boolean(formik.errors.cardDisplayName)
            }
            helperText={
              formik.touched.cardDisplayName && formik.errors.cardDisplayName
            }
          />
        </Grid>
        {config?.ADDRESS_FINDER_KEY && (
          <AddressFinderForm
            formik={{ resetForm, ...formik }}
            countryCode={employer.data?.country.id}
          />
        )}

        {termsAndConditions && (
          <Grid item container>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAcceptedTermsAndConditions}
                  onChange={(e) =>
                    setIsAcceptedTermsAndConditions(e.target.checked)
                  }
                />
              }
              label={
                <Typography fontSize={14}>
                  Agree to HealthNow{" "}
                  <a
                    href="https://healthnow1.wpenginepowered.com/wp-content/uploads/2024/01/HealthNow-Card-Terms-and-Conditions-22Jan24.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: theme.palette.primary.main }}
                  >
                    terms and conditions
                  </a>
                </Typography>
              }
            />
          </Grid>
        )}

        <Grid item container direction="row">
          {error && <Typography color="error">{error}</Typography>}
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end">
          {showCancel && (
            <Grid item>
              <Button
                onClick={() => {
                  resetData();
                  navigator(-1);
                }}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={
                mutation.isLoading || employee.isLoading || employer.isLoading
              }
            >
              {submitButtonText}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
