import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography
} from "@mui/material";
import Persona from "persona";
import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";
import { KycStatus, UserInfo } from "../models";
import { useConfig } from "../utils/useConfig";
import { useData } from "../utils/useData";
import { LoadingSpinner } from "./LoadingSpinner";
import { PersonalDetailsForm } from "./PersonalDetailsForm";

type KycProps = {
  userInfoData: UserInfo;
  onSuccess: () => void;
};

const DEFAULT_ERROR_MESSAGE =
  "There was a problem verifying your identity. You can either try again or contact us if the problem persists.";

export const Kyc = ({ userInfoData, onSuccess }: KycProps) => {
  const { config } = useConfig();
  const { employee } = useData();

  const [errorMessage, setErrorMessage] = useState("");
  const [inquiryIdFromSessionStore, SetInquiryIdFromSessionStore] =
    useSessionStorage<string | undefined>(
      `${userInfoData.employeeId}-in-progress-inquiry-id`,
      undefined
    );
  const [isConfirmDetails, setIsConfirmDetails] = useSessionStorage(
    `${userInfoData.employeeId}-is-confirm-details`,
    true
  );

  const [hasSavedInquiry, setHasSavedInquiry] = useState<boolean>(false);
  const [kycPassed, setKycPassed] = useState<boolean>(false);

  const getClient = (inquiryId: string | undefined) => {
    return new Persona.Client({
      // only templateId OR inquiryId should be supplied
      templateId: inquiryId ? undefined : config?.PERSONA_TEMPLATE_ID,
      inquiryId: inquiryId,
      environmentId: config?.PERSONA_ENVIRONMENT_ID,
      referenceId: userInfoData?.employeeId,
      onComplete: ({ inquiryId, status, fields }) => {
        if (status === "completed") {
          onSuccess();
        } else {
          setErrorMessage(DEFAULT_ERROR_MESSAGE);
        }
      },
      onCancel: ({ inquiryId }) => {
        setErrorMessage("Please resume verifying your identity.");
        SetInquiryIdFromSessionStore(inquiryId);
      },
      onError: (error) =>
        setErrorMessage(error.message || DEFAULT_ERROR_MESSAGE)
    });
  };

  const startNewVerification = () => {
    getClient(undefined).open();
  };

  const resumeVerification = () => {
    getClient(
      employee.data?.stats?.kyc?.inquiryId ?? inquiryIdFromSessionStore
    ).open();
  };

  useEffect(() => {
    setHasSavedInquiry(
      !!(employee.data?.stats?.kyc?.inquiryId ?? inquiryIdFromSessionStore)
    );
    setKycPassed(employee.data?.stats?.kyc?.status === KycStatus.APPROVED);
  }, [employee, inquiryIdFromSessionStore]);

  if (employee.isLoading || employee.isIdle) return <LoadingSpinner />;

  // If they make it past isConfirmDetails but still don't have address set, then still show confirm details
  if (isConfirmDetails || !employee.data?.contact.address.address1)
    return (
      <>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6">Please confirm your details</Typography>
            }
            sx={{ pb: 0 }}
          />
          <CardContent>
            <Typography color="grey">
              Some of your details have been provided by your employer. Please
              check they are correct and update them.
            </Typography>
          </CardContent>
        </Card>
        <PersonalDetailsForm
          showCancel={false}
          submitButtonText="Confirm"
          onSuccess={() => setIsConfirmDetails(false)}
          termsAndConditions
        />
      </>
    );

  // If they reach this point and have already passed KYC (shouldn't be possible) -> Forward them on to prevent
  // duplicate KYC entries.
  if (kycPassed) {
    onSuccess();
    return <></>;
  }

  return (
    <>
      {errorMessage && (
        <Card variant="outlined" sx={{ mb: 2, borderColor: "error.main" }}>
          <CardContent>
            <Typography color="error">{errorMessage}</Typography>
          </CardContent>
        </Card>
      )}
      <Card>
        <CardHeader
          title={
            <Typography variant="h6">Please verify your identity!</Typography>
          }
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Typography color="grey">
            To use your card for purchases we need to be sure who you are — only
            takes 3 minutes.
          </Typography>
        </CardContent>
        {hasSavedInquiry && (
          <>
            <CardActionArea>
              <Button onClick={resumeVerification}>Resume verification!</Button>
            </CardActionArea>
            <CardActionArea>
              <Button onClick={startNewVerification}>
                Start new verification!
              </Button>
            </CardActionArea>
          </>
        )}
        {!hasSavedInquiry && (
          <CardActionArea>
            <Button onClick={startNewVerification}>Verify Me!</Button>
          </CardActionArea>
        )}
      </Card>
    </>
  );
};
